import { React, Fragment, useState } from "react";
import Navbar from "../../Components/Navbar";
import Topbar from "../../Components/Topbar";
import { Dialog, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import CustomersBreadcrumb from "../../Components/CustomersBreadcrumb";
import { sendGetRequest, sendPostRequest, useGetRequest } from "../../Client";
import CustomerRateAdd from "./add";
import CustomerRateUpdate from "./update";
import { useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ReactLoading from "react-loading";
import { Notyf } from "notyf";
import Swal from "sweetalert2";
import CustomerName from "../../Components/CustomerName";

function CustomerRates() {
  const { customerID } = useParams();

  const { status, data } = useGetRequest("customer/" + customerID, {});

  const queryClient = useQueryClient();

  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);

  const [rateID, setRateID] = useState();

    // confirm delete
    const areYouSure = (id) => {
        Swal.fire({
          title: "Are you sure?",
          showCancelButton: true,
          confirmButtonText: "Delete",
          confirmButtonColor: "#de3333",
        }).then((result) => {
          if (result.isConfirmed) {
            doDeleteMutation(id);
          } else if (result.isDenied) {
            Swal.fire("Changes are not saved", "", "info");
          }
        });
      };

  // confirmation notifications
  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
  });

  const updatedNotyf = () => {
    notyf.success("Rate updated");
  };

  const addedNotyf = () => {
    notyf.success("Rate added");
  };

  const deletedNotyf = () => {
    notyf.success({ message: "Rate deleted", background: "#FF0000" });
  };

  // loading rates
  const { data: rates, isLoading } = useQuery({
    queryFn: () => sendGetRequest("customer/rates/" + customerID, {}),
    queryKey: ["rates"],
  });

  // deleting rates
  const rateDelete = async (id) => {
    const res = await sendPostRequest("customer/rate/delete", {
      customerRateID: id,
    });
  };

  const { mutateAsync: doDeleteMutation } = useMutation({
    mutationFn: (id) => {
      return rateDelete(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["rates"] });
      deletedNotyf();
    },
  });



  const { status: statusRateTypes, data: dataRateTypes } = useGetRequest(
    "customer/ratesTypes",
    {}
  );

  if (statusRateTypes == "fetched") {
    return (
      <>
        <div className="flex flex-row w-full">
          <Navbar />

          <div className="flex flex-col w-full bg-gray-100 ml-60">
            <Topbar />

            <CustomersBreadcrumb />

            <CustomerName customerName={data?.customer?.customerName} />

            {/* content */}
            <div className="bg-white px-4 sm:px-6 lg:px-8 border border-gray-200 ml-10 mr-10 mt-10 mb-40">
              <form>
                <div className="space-y-12">
                  <div className="mt-10">
                    <div className="sm:flex sm:items-center">
                      <div className="sm:flex-auto">
                        <h1 className="text-2xl font-semibold leading-6 text-gray-900 mb-5">
                          Rates
                        </h1>
                        <p className="mt-2 text-sm text-gray-700"></p>
                      </div>
                      <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                        <button
                          type="button"
                          onClick={() => setOpen(true)}
                          className="block rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                        >
                          Add Rate
                        </button>
                      </div>
                    </div>
                    <div className="mt-8 flow-root mb-10">
                      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-medium text-gray-900 sm:pl-0"
                                >
                                  Charge Type
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-medium text-gray-900"
                                >
                                  Value
                                </th>

                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-medium text-gray-900"
                                ></th>
                              </tr>
                            </thead>

                            {!isLoading ? (
                              rates.rates?.length > 0 ? (
                                <tbody className="divide-y divide-gray-200">
                                  {rates.rates?.map((item) => (
                                    <tr key={item.customerRateID}>
                                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-700 sm:pl-0">
                                        {item.chargeName}
                                      </td>
                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        ${item.rate}
                                      </td>

                                      <td className="block text-right">
                                        <span className="mt-2 isolate inline-flex rounded-md shadow-sm">
                                          <button
                                            type="button"
                                            onClick={() => {
                                              setRateID(item.customerRateID);
                                              setEditOpen(true);
                                            }}
                                            className="relative inline-flex items-center rounded-l-md bg-amber-500 hover:bg-amber-400 focus-visible:outline-amber-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 focus:z-10"
                                          >
                                            Edit
                                          </button>

                                          <button
                                            type="button"
                                            onClick={() => {
                                              if (
                                                areYouSure(item.customerRateID)
                                              ) {
                                                rateDelete(
                                                  item.customerRateID
                                                );
                                              }
                                            }}
                                            className="relative -ml-px inline-flex items-center rounded-r-md bg-red-500 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-red-400 focus:z-10"
                                          >
                                            Delete
                                          </button>
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              ) : (
                                <>
                                  <tbody className="divide-y divide-gray-200">
                                    <tr>
                                      <td
                                        colSpan="4"
                                        className="w-full text-center whitespace-nowrap px-3 py-4 text-sm text-gray-500 col-span-6"
                                      >
                                        -- No rates found --
                                      </td>
                                    </tr>
                                  </tbody>
                                </>
                              )
                            ) : (
                              <>
                                <tbody className="divide-y divide-gray-200">
                                  <tr>
                                    <td
                                      colSpan="5"
                                      className="w-full text-center whitespace-nowrap px-3 py-4 text-sm text-gray-500 col-span-6"
                                    >
                                      <ReactLoading
                                        type={"spin"}
                                        color="#5046e5"
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </>
                            )}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {/* content */}
          </div>
        </div>

        <CustomerRateAdd
          open={open}
          setOpen={setOpen}
          dataRateTypes={dataRateTypes}
          addedNotyf={addedNotyf}
          customerID={customerID}
          queryClient={queryClient}
        />
        {rateID && (
          <CustomerRateUpdate
            editOpen={editOpen}
            setEditOpen={setEditOpen}
            dataRateTypes={dataRateTypes}
            updatedNotyf={updatedNotyf}
            customerID={customerID}
            queryClient={queryClient}
            rateID={rateID}
          />
        )}
      </>
    );
  }
}

export default CustomerRates;
