import Navbar from "../Components/Navbar";
import Topbar from "../Components/Topbar";
import { useLayoutEffect, useEffect, useState } from "react";
import { sendPostRequest, useGetRequest } from "../Client";
import { React, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
import Swal from "sweetalert2";

function Locations() {
  const initialDate = new Date().toISOString().split("T")[0];
  const initialDateArray = initialDate.split("-");

  const [inputs, setInputs] = useState({
    date:
      initialDateArray[0] +
      "-" +
      initialDateArray[1] +
      "-" +
      initialDateArray[2],
  });
  const [open, setOpen] = useState(false);
  const [receivedList, setReceivedList] = useState([]);
  const [locations, setLocations] = useState([]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const locationDelete = async (id) => {
    const res = await sendPostRequest("location/delete", {
      locationID: id,
    });

    setTimeout(() => {
      window.location.reload(false);
    }, 500);

    deletedNotyf();
  };

  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
  });

    // confirm delete
    const areYouSure = (id) => {
      Swal.fire({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Delete",
        confirmButtonColor: "#de3333",
      }).then((result) => {
        if (result.isConfirmed) {
          locationDelete(id);
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
    };

  const addedNotyf = () => {
    notyf.success("Location added");
  };

  const deletedNotyf = () => {
    notyf.success({ message: "Location deleted", background: "#FF0000" });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const requestBody = {
      locationName: inputs.locationName,
    };

    const res = await sendPostRequest("location/add", requestBody);

    setTimeout(() => {
      window.location.reload(false);
    }, 500);

    addedNotyf();

    setOpen(false);
  };

  const { status: statusLocations, data: dataLocations } = useGetRequest(
    "locations",
    {}
  );

  useEffect(() => {
    setReceivedList(dataLocations.locations);
  }, [dataLocations]);

  if (statusLocations == "fetched") {
    return (
      <>
        <div className="flex flex-row">
          <Navbar />

          <div className="flex flex-col w-full bg-gray-100 ml-60">
            <Topbar />

            <nav className="flex ml-10 mt-5 h-10" aria-label="Breadcrumb">
              <ol
                role="list"
                className="flex space-x-4 rounded-md bg-white px-6 shadow"
              >
                <li className="flex">
                  <div className="flex items-center">
                    <a
                      href="/receiving"
                      className="text-gray-400 hover:text-gray-500"
                    >
                      <span>Back</span>
                    </a>
                  </div>
                </li>
              </ol>
            </nav>

            <div className="bg-white px-4 sm:px-6 lg:px-8 border border-gray-200 ml-10 mr-10 mt-10 mb-60">
              <form onSubmit={handleSubmit}>
                <div className="space-y-12">
                  <div className="pb-5 mt-8">
                    <div className="sm:flex sm:items-center">
                      <div className="sm:flex-auto flex flex-row">
                        <h1 className="text-2xl font-semibold leading-6 text-gray-900">
                          Locations
                        </h1>
                      </div>

                      <div className="mt-4 sm:ml-8 sm:mt-0 sm:flex-none">
                        <button
                          type="button"
                          onClick={() => setOpen(true)}
                          className="block rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                        >
                          Add Location
                        </button>
                      </div>
                    </div>
                    <div className="mt-8 flow-root mb-5">
                      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  className="w-1/6 py-3.5 pl-4 pr-3 text-left text-sm font-medium text-gray-900 sm:pl-0"
                                >
                                  Location Name
                                </th>

                                <th
                                  scope="col"
                                  className="w-1/6 px-3 py-3.5 text-left text-sm font-medium text-gray-900"
                                ></th>
                              </tr>
                            </thead>

                            {receivedList?.length > 0 ? (
                              <tbody className="divide-y divide-gray-200">
                                {receivedList?.map((item, key) => (
                                  <tr>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                      {item.name}
                                    </td>

                                    <td className="block text-right">
                                      <span className="mt-2 isolate inline-flex rounded-md shadow-sm">
                                        <button
                                          type="button"
                                          onClick={() =>
                                            areYouSure(item.locationID)
                                          }
                                          className="relative -ml-px inline-flex items-center rounded-l-md rounded-r-md bg-red-500 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-red-400 focus:z-10"
                                        >
                                          Delete
                                        </button>
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            ) : (
                              <>
                                <tbody className="divide-y divide-gray-200">
                                  <tr>
                                    <td
                                      colSpan="5"
                                      className="w-full text-center whitespace-nowrap px-3 py-4 text-sm text-gray-500 col-span-6"
                                    >
                                      -- No Locations found --
                                    </td>
                                  </tr>
                                </tbody>
                              </>
                            )}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {/* content */}
          </div>
        </div>

        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setOpen}>
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                      <form onSubmit={handleSubmit}>
                        <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                          <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                            <div className="flex items-center justify-between">
                              <Dialog.Title className="text-lg font-medium text-gray-900"></Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                                  onClick={() => setOpen(false)}
                                ></button>
                              </div>
                            </div>

                            <table className="min-w-full divide-y divide-gray-300 mt-10">
                              <tbody className="divide-gray-200">
                                <tr>
                                  <td>
                                    {" "}
                                    <label
                                      htmlFor="locationName"
                                      className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                                    >
                                      Location Name
                                    </label>
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                                    <input
                                      type="text"
                                      name="locationName"
                                      onChange={handleChange}
                                      // value={inputs.skids || ""}
                                      value={inputs.locationName || ""}
                                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>

                            <div className="mt-5">
                              <button
                                type="submit"
                                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              >
                                Add Location
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

export default Locations;
