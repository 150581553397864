import { React, useState } from "react";
import Navbar from "../../Components/Navbar";
import Topbar from "../../Components/Topbar";
import { useParams } from "react-router-dom";
import { sendGetRequest, sendPostRequest, useGetRequest } from "../../Client";
import ReactLoading from "react-loading";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
import Swal from "sweetalert2";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import CustomersBreadcrumb from "../../Components/CustomersBreadcrumb";
import { Link } from "react-router-dom";
import CustomerCarrierAdd from "./add";
import CustomerName from "../../Components/CustomerName";

function CustomerCarriers() {
  const { customerID } = useParams();

  const { status, data } = useGetRequest("customer/" + customerID, {});

  const [open, setOpen] = useState(false);

  const queryClient = useQueryClient();

  // confirmation notifications
  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
  });

  const addedNotyf = () => {
    notyf.success("Setting added");
  };

  const deletedNotyf = () => {
    notyf.success({ message: "Setting deleted", background: "#FF0000" });
  };

  // loading carriers
  const { data: carriers, isLoading } = useQuery({
    queryFn: () => sendGetRequest("customers/carriers/" + customerID, {}),
    queryKey: ["carriers"],
  });

  // loading carriers
  const { data: carrierDropdown, isLoadingDropdown } = useQuery({
    queryFn: () => sendGetRequest("customers/carriers/dropdown/" + customerID, {}),
    queryKey: ["carriersDropdown"],
  });
  

  if (!isLoading) {
    return (
      <>
        <div className="flex flex-row w-full">
          <Navbar />

          <div className="flex flex-col w-full bg-gray-100 ml-60">
            <Topbar />

            <CustomersBreadcrumb />

            <CustomerName customerName={data?.customer?.customerName} />

            <div className="bg-white px-4 sm:px-6 lg:px-8 border border-gray-200 ml-10 mr-10 mt-10 mb-40">
              <form>
                <div className="space-y-12">
                  <div className="mt-10">
                    <div className="sm:flex sm:items-center">
                      <div className="sm:flex-auto">
                        <h1 className="text-2xl font-semibold leading-6 text-gray-900 mb-5">
                          Carriers
                        </h1>
                      </div>
                      <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                        <button
                          type="button"
                          onClick={() => setOpen(true)}
                          className="block rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                        >
                          Add Carrier
                        </button>
                      </div>
                    </div>
                    <div className="mt-8 flow-root mb-10">
                      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-medium text-gray-900 sm:pl-0"
                                >
                                  Carrier
                                </th>

                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-medium text-gray-900"
                                ></th>
                              </tr>
                            </thead>
                            {!isLoading ? (
                              carriers?.carriers?.length > 0 ? (
                                <tbody className="divide-y divide-gray-200">
                                  {carriers.carriers?.map((item) => (
                                    <tr key={item.carrierID}>
                                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-700 sm:pl-0">
                                        {item.carrierName}
                                      </td>

                                      <td className="block text-right">
                                        <Link
                                          to={`/customer/carriers/settings/${customerID}/${item.carrierID}`}
                                        >
                                          <span className="mt-2 isolate inline-flex rounded-md shadow-sm">
                                            <button
                                              type="button"
                                              onClick={() => {}}
                                              className="relative -ml-px inline-flex items-center rounded-md bg-slate-500 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-slate-400 focus:z-10"
                                            >
                                              Settings
                                            </button>
                                          </span>
                                        </Link>

                                        <Link
                                          to={`/customer/carriers/services/${customerID}/${item.carrierID}`}
                                        >
                                          <span className="mt-2 isolate inline-flex rounded-md shadow-sm ml-4">
                                            <button
                                              type="button"
                                              onClick={() => {}}
                                              className="relative -ml-px inline-flex items-center rounded-md bg-sky-500 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-sky-300 hover:bg-sky-400 focus:z-10"
                                            >
                                              Services
                                            </button>
                                          </span>
                                        </Link>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              ) : (
                                <>
                                  <tbody className="divide-y divide-gray-200">
                                    <tr>
                                      <td
                                        colSpan="4"
                                        className="w-full text-center whitespace-nowrap px-3 py-4 text-sm text-gray-500 col-span-6"
                                      >
                                        -- No Carriers added --
                                      </td>
                                    </tr>
                                  </tbody>
                                </>
                              )
                            ) : (
                              <>
                                <tbody className="divide-y divide-gray-200">
                                  <tr>
                                    <td
                                      colSpan="5"
                                      className="w-full text-center whitespace-nowrap px-3 py-4 text-sm text-gray-500 col-span-6"
                                    >
                                      <ReactLoading
                                        type={"spin"}
                                        color="#5046e5"
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </>
                            )}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>


        <CustomerCarrierAdd
          open={open}
          setOpen={setOpen}
          carrierDropdown={carrierDropdown}
          addedNotyf={addedNotyf}
          customerID={customerID}
          queryClient={queryClient}
        />

      </>
    );
  }
}

export default CustomerCarriers;
