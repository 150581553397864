import { React, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Navbar from "../Components/Navbar";
import Topbar from "../Components/Topbar";
import { useLayoutEffect, useRef, useState } from "react";
import { sendPostRequest, useGetRequest, sendGetRequest } from "../Client";
import { Notyf } from "notyf";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function ChargeTypes() {
  const [inputs, setInputs] = useState({});

  const [chargeTypeID, setChargeTypeID] = useState();
  const [chargeTypeName, setChargeTypeName] = useState();
  const [chargeTypeAmount, setChargeTypeAmount] = useState();

  const [editOpen, setEditOpen] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const queryClient = useQueryClient();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const requestBody = {
      chargeTypeName: inputs.chargeTypeName,
      defaultCharge: inputs.defaultCharge,
    };

    setTimeout(() => {
      window.location.reload(false);
    }, 500);

    const res = await sendPostRequest("charges/add", requestBody);
  };

  // const { status: statusChargeTypes, data: dataChargeTypes } = useGetRequest(
  //   "charges/list",
  //   {}
  // );

    // loading rates
    const { data: dataChargeTypes, isLoading } = useQuery({
      queryFn: () => sendGetRequest("charges/list", {}),
      queryKey: ["charges"],
    });

  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
  });

  const updatedNotyf = () => {
    notyf.success("Rate updated");
  };

  // updating inventory
  const updateRate = async (path, body) => {
    const res = await sendPostRequest(path, body);
    updatedNotyf();
  };

  const { mutateAsync: doUpdateMutation } = useMutation({
    mutationFn: (data) => {
      return updateRate(data.path, { chargeTypeID, ...data.body });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["charges"] });
    },
  });

  if (!isLoading) {
    return (
      <>
        <div className="flex flex-row w-full">
          <Navbar />

          <div className="flex flex-col w-full bg-gray-100 ml-60">
            <Topbar />

            {/* content */}
            <div className="bg-white px-4 sm:px-6 lg:px-8 border border-gray-200 ml-10 mr-10 mt-10 mb-10">
              <form onSubmit={handleSubmit}>
                <div className="border px-2 py-2 mt-5">
                  <div className="w-full flex flex-row mt-4 justify-start align-end">
                    <div className="w-1/2">
                      <div className="mb-5 mx-2">
                        <label
                          htmlFor="company"
                          className="block text-sm font-medium text-gray-900 mb-3"
                        >
                          Charge Type Name
                        </label>
                        <div className="">
                          <input
                            type="text"
                            name="chargeTypeName"
                            onChange={handleChange}
                            required="required"
                            value={inputs.chargeTypeName || ""}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="w-1/2">
                      <div className="mb-5 mx-2">
                        <label
                          htmlFor="company"
                          className="block text-sm font-medium text-gray-900 mb-3"
                        >
                          Default Charge ($)
                        </label>
                        <div className="">
                          <input
                            type="number"
                            name="defaultCharge"
                            onChange={handleChange}
                            required="required"
                            value={inputs.defaultCharge || ""}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full grid justify-items-end">
                    <button
                      type="submit"
                      className="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Add Charge Type
                    </button>
                  </div>
                </div>
              </form>

              <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <div className="relative">
                      <table className="min-w-full table-fixed divide-y divide-gray-300">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="w-1/7 py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                            >
                              Charge Type Name
                            </th>
                            <th
                              scope="col"
                              className="w-1/7 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Default Charge
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {dataChargeTypes.list.map((list) => (
                            <tr>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {list.chargeName}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                ${list.defaultRate}
                              </td>

                              <td className="block text-right">
                                <span className="mt-2 isolate inline-flex rounded-md shadow-sm">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setChargeTypeID(list.chargeTypeID);
                                      setChargeTypeName(list.chargeName);
                                      setChargeTypeAmount(list.defaultRate);
                                      setEditOpen(true);
                                    }}
                                    className="relative -ml-px inline-flex items-center rounded-md bg-amber-500 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-amber-400 focus:z-10"
                                  >
                                    Edit
                                  </button>
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* content */}
          </div>
        </div>

        <Transition.Root show={editOpen} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setEditOpen}>
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                      <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                        <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                          <div className="flex items-center justify-between">
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                                onClick={() => setEditOpen(false)}
                              ></button>
                            </div>
                          </div>

                          <table className="min-w-full divide-y divide-gray-300 mt-10">
                            <tbody className="divide-gray-200">
                              <tr className="min-w-1/2">
                                <td>
                                  {" "}
                                  <label
                                    htmlFor="chargeTypeName"
                                    className="block text-sm font-medium leading-6 text-gray-600 sm:pt-1.5"
                                  >
                                    Name
                                  </label>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                                  <input
                                    type="text"
                                    name="chargeTypeName"
                                    id="chargeTypeName"
                                    value={chargeTypeName ? chargeTypeName : ""}
                                    onChange={(e) => {
                                      setChargeTypeName(e.target.value);
                                    }}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </td>
                              </tr>

                              <tr className="min-w-1/2">
                                <td>
                                  {" "}
                                  <label
                                    htmlFor="chargeTypeAmount"
                                    className="block text-sm font-medium leading-6 text-gray-600 sm:pt-1.5"
                                  >
                                    Value
                                  </label>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                                  <input
                                    type="text"
                                    name="chargeTypeAmount"
                                    id="chargeTypeAmount"
                                    value={
                                      chargeTypeAmount ? chargeTypeAmount : ""
                                    }
                                    onChange={(e) => {
                                      setChargeTypeAmount(e.target.value);
                                    }}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                          <div className="mt-6">
                            <a
                              onClick={async () => {
                                try {
                                  await doUpdateMutation({
                                    path: "charges/update",
                                    body: {
                                      defaultRate: chargeTypeAmount,
                                      chargeName: chargeTypeName,
                                      chargeTypeID: chargeTypeID,
                                    },
                                  });
                                  setEditOpen(false);
                                } catch (e) {
                                  console.log(e);
                                }
                              }}
                              className="w-[100%] flex items-center justify-center rounded-md border border-transparent bg-amber-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-amber-700"
                            >
                              Edit Item
                            </a>
                          </div>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

export default ChargeTypes;
