import Navbar from "../Components/Navbar";
import Topbar from "../Components/Topbar";
import {
  useLayoutEffect,
  useRef,
  useState,
  Fragment,
  React,
  useEffect,
} from "react";
import { Link } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import {
  usePostRequest,
  sendGetRequest,
  useGetRequest,
  sendPostRequest,
} from "../Client";
import { dateFormatter, domain } from "../config";
import Swal from "sweetalert2";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Dashboard() {
  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [searchCustomer, setSearchCustomer] = useState(0);

  const [dashboardList, setDashboardList] = useState([]);

  const [picking, setPicking] = useState(false);

  const { status, data } = useGetRequest("orders/dashboard", {});

  // customers dropdown
  const { status: statusCustomers, data: dataCustomers } = useGetRequest(
    "customers",
    {}
  );

  const fetchCustomerDashboard = async (searchCustomer) => {
    const res = await sendGetRequest("orders/dashboard/" + searchCustomer, {});
    console.log("res", res);
    setDashboardList(res.orders);
  };

  useEffect(() => {
    fetchCustomerDashboard(searchCustomer);
  }, [searchCustomer]);

  useEffect(() => {
    setDashboardList(data.orders);
  }, [data]);

  const confirmPickingSlip = async () => {
    // create the record in the picking_list table && update status of orders
    const res = await sendPostRequest("picking-slip-create", {
      selectedOrders: JSON.stringify(selectedOrders),
    });

    if (res.status == "success") {
      window.open(domain + `/picking-slip/${res.hash}/` + res.pickingListID);

      fetchCustomerDashboard(0);
    }
  };

  function toggleAll() {
    setSelectedOrders(checked || indeterminate ? [] : dashboardList);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  // to be removed
  const dataDelete = async (id) => {
    const res = await sendPostRequest("test/data/delete", {});

    setTimeout(() => {
      window.location.reload(false);
    }, 500);
  };

  // confirm delete wipe data
  const areYouSure = (id) => {
    Swal.fire({
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#de3333",
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("delete", id);
        dataDelete(id);
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  if (status == "fetched" && statusCustomers == "fetched") {
    return (
      <>
        <div className="flex flex-row justify-start w-full">
          <Navbar />

          <div className="flex flex-col w-full bg-gray-100 ml-60">
            <Topbar />
            {/* content */}

            {dashboardList?.length > 0 ? (
              <div className="bg-white px-4 sm:px-6 lg:px-8 border border-gray-200 ml-10 mr-10 mt-10 mb-64">
                <div className="sm:flex sm:items-center">
                  <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900 mt-10">
                      New Orders
                    </h1>
                    <p className="mt-2 text-sm text-gray-700">
                      There are {dashboardList.length} new orders that need to
                      be processed
                    </p>
                  </div>

                  <div className="mt-2">
                    <select
                      id="customer"
                      name="customer"
                      onChange={(e) => {
                        setSearchCustomer(e.target.value);
                      }}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    >
                      <option selected key={"All"} value={0}>
                        All
                      </option>
                      {dataCustomers.customers.map((customer) => (
                        <option
                          key={customer.customerName}
                          value={customer.customerID}
                        >
                          {customer.customerName}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    {selectedOrders.length > 0 && (
                      <button
                        type="button"
                        onClick={
                          () => setPicking(true)
                          // pickingslip()
                        }
                        className="block rounded-md bg-indigo-600 px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Picking List
                      </button>
                    )}
                    {selectedOrders.length == 0 && (
                      <button
                        type="button"
                        className="block rounded-md bg-slate-100 px-3 py-1.5 text-center text-sm font-semibold leading-6 text-slate-300 shadow-sm "
                      >
                        Select Orders
                      </button>
                    )}
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={() => {
                        areYouSure();
                      }}
                      className="ml-3 block rounded-md bg-red-500 px-3 py-1.5 text-center text-sm font-semibold leading-6 text-red-200 shadow-sm "
                    >
                      Wipe Data
                    </button>
                  </div>
                </div>
                <div className="mt-8 flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-8 align-middle sm:px-6 lg:px-8">
                      <div className="relative">
                        <table className="min-w-full table-fixed divide-y divide-gray-300">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="w-1/7 relative px-7 sm:w-12 sm:px-6"
                              >
                                <input
                                  type="checkbox"
                                  className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                  ref={checkbox}
                                  checked={checked}
                                  onChange={toggleAll}
                                />
                              </th>
                              <th
                                scope="col"
                                className="w-1/7 py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                              >
                                Status
                              </th>
                              <th
                                scope="col"
                                className="w-1/7 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Order Number
                              </th>
                              <th
                                scope="col"
                                className="w-1/7 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Date
                              </th>
                              <th
                                scope="col"
                                className="w-1/7 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Customer
                              </th>
                              <th
                                scope="col"
                                className="w-1/8 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Items
                              </th>
                              <th
                                scope="col"
                                className="w-1/7 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Destination
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {dashboardList.map((order) => (
                              <tr
                                key={
                                  order.email + order.orderID + Math.random()
                                }
                                className={
                                  "mb-12" + selectedOrders.includes(order)
                                    ? "bg-gray-50"
                                    : undefined
                                }
                              >
                                <td className="relative px-7 sm:w-12 sm:px-6">
                                  {selectedOrders.includes(order) && (
                                    <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                                  )}
                                  <input
                                    type="checkbox"
                                    className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    value={order.email}
                                    checked={selectedOrders.includes(order)}
                                    onChange={(e) =>
                                      setSelectedOrders(
                                        e.target.checked
                                          ? [...selectedOrders, order]
                                          : selectedOrders.filter(
                                              (p) => p !== order
                                            )
                                      )
                                    }
                                  />
                                </td>
                                <td
                                  className={classNames(
                                    "whitespace-nowrap py-4 pr-3 text-sm font-medium",
                                    selectedOrders.includes(order)
                                      ? "text-indigo-600"
                                      : "text-gray-900"
                                  )}
                                >
                                  {order.statusName == "New Order" ? (
                                    <span className="inline-flex items-center rounded-md bg-green-700 px-2 py-1 text-xs font-medium text-white ring-1 ring-inset ring-green-600/20">
                                      {order.statusName}
                                    </span>
                                  ) : (
                                    <span className="inline-flex items-center rounded-md bg-amber-700 px-2 py-1 text-xs font-medium text-white ring-1 ring-inset ring-green-600/20">
                                      {order.statusName}
                                    </span>
                                  )}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hover:text-indigo-900 underline hover:cursor-pointer">
                                  <Link to={`/order/${order.orderID}`}>
                                    {order.orderID}
                                  </Link>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {dateFormatter(order.orderDate)}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {order.customerName}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {order.totalQuantity}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {order.orderCity}
                                </td>
                                <td>
                                  {" "}
                                  {order.orderCountry == "Canada" ? (
                                    <img
                                      width="20px"
                                      alt="Canada"
                                      src="http://purecatamphetamine.github.io/country-flag-icons/3x2/CA.svg"
                                    />
                                  ) : order.orderCountry == "United States" ? (
                                    <img
                                      width="20px"
                                      alt="United States"
                                      src="http://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg"
                                    />
                                  ) : order.orderCountry == "Mexico" ? (
                                    <img
                                      width="20px"
                                      alt="Mexico"
                                      src="http://purecatamphetamine.github.io/country-flag-icons/3x2/MX.svg"
                                    />
                                  ) : null}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="bg-white px-4 sm:px-6 lg:px-8 border border-gray-200 ml-10 mr-10 mt-10 mb-64">
                <div className="sm:flex sm:items-center">
                  <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900 mt-10">
                      New Orders
                    </h1>
                    <p className="mt-2 mb-10 text-sm text-gray-700">
                      0 orders found
                    </p>
                  </div>

                  <div className="mt-2">
                    <select
                      id="customer"
                      name="customer"
                      onChange={(e) => {
                        setSearchCustomer(e.target.value);
                      }}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    >
                      <option selected key={"All"} value={0}>
                        All
                      </option>
                      {dataCustomers.customers.map((customer) => (
                        <option
                          key={customer.customerName}
                          value={customer.customerID}
                        >
                          {customer.customerName}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    {selectedOrders.length > 0 && (
                      <button
                        type="button"
                        onClick={
                          () => setPicking(true)
                          // pickingslip()
                        }
                        className="block rounded-md bg-indigo-600 px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Picking List
                      </button>
                    )}
                    {selectedOrders.length == 0 && (
                      <button
                        type="button"
                        className="block rounded-md bg-slate-100 px-3 py-1.5 text-center text-sm font-semibold leading-6 text-slate-300 shadow-sm "
                      >
                        Select Orders
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <Transition.Root show={picking} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setPicking}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                    <div>
                      <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                        <CheckIcon
                          className="h-6 w-6 text-green-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center sm:mt-5">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Confirm picking list
                        </Dialog.Title>
                        <div className="mt-4 text-sm font-semibold text-gray-500">
                          Are you sure you would like to add these orders to
                          your picking list?
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-6 flex flex-row justify-between">
                      <div>
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                          onClick={() => setPicking(false)}
                        >
                          Cancel
                        </button>
                      </div>
                      <div>
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                          onClick={() => {
                            setPicking(false);
                            confirmPickingSlip();
                          }}
                        >
                          Confirm
                        </button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

export default Dashboard;
