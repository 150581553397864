import { React, useState } from "react";
import Navbar from "../../Components/Navbar";
import Topbar from "../../Components/Topbar";
import { useParams } from "react-router-dom";
import { sendGetRequest, sendPostRequest } from "../../Client";
import ReactLoading from "react-loading";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
import Swal from "sweetalert2";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import CustomerCategoryAdd from "./add";
import CustomerCategoryUpdate from "./update";

function CustomerCategories() {
  const { customerID } = useParams();
  const { divisionID } = useParams();

  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);

  const queryClient = useQueryClient();

  const [customerCategoryID, setCustomerCategoryID] = useState();

  // confirm delete
  const areYouSure = (id) => {
    Swal.fire({
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#de3333",
    }).then((result) => {
      if (result.isConfirmed) {
        doDeleteMutation(id);
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  // confirmation notifications
  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
  });

  const updatedNotyf = () => {
    notyf.success("Category updated");
  };

  const addedNotyf = () => {
    notyf.success("Category added");
  };

  const deletedNotyf = () => {
    notyf.success({ message: "Category deleted", background: "#FF0000" });
  };

  // loading categories
  const { data: customerCategories, isLoading } = useQuery({
    queryFn: () =>
      sendGetRequest("inventory/customer/categories/" + customerID + "/" + divisionID, {}),
    queryKey: ["customerCategories"],
  });

  // deleting division
  const customerCategoryDelete = async (id) => {
    const res = await sendPostRequest("customer/category/delete", {
      categoryID: id,
    });
  };

  const { mutateAsync: doDeleteMutation } = useMutation({
    mutationFn: (id) => {
      return customerCategoryDelete(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["customerCategories"] });
      deletedNotyf();
    },
  });

  if (!isLoading) {
    return (
      <>
        <div className="flex flex-row w-full">
          <Navbar />

          <div className="flex flex-col w-full bg-gray-100 ml-60">
            <Topbar />

            <nav className="flex ml-10 mt-5 h-10" aria-label="Breadcrumb">
              <ol
                role="list"
                className="flex space-x-4 rounded-md bg-white px-6 shadow"
              >
                <li className="flex">
                  <div className="flex items-center">
                    <a
                      href={`/divisions/${customerID}`}
                      className="text-gray-400 hover:text-gray-500"
                    >
                      <span>Back</span>
                    </a>
                  </div>
                </li>
              </ol>
            </nav>

            <div className="bg-white px-4 sm:px-6 lg:px-8 border border-gray-200 ml-10 mr-10 mt-10 mb-40">
              <form>
                <div className="space-y-12">
                  <div className="mt-10">
                    <div className="sm:flex sm:items-center">
                      <div className="sm:flex-auto">
                        <h1 className="text-2xl font-semibold leading-6 text-gray-900 mb-5">
                          {customerCategories.divisionName}
                        </h1>
                      </div>

                      <div className="mt-4 sm:ml-4 sm:mt-0 sm:flex-none">
                        <button
                          type="button"
                          onClick={() => setOpen(true)}
                          className="block rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                        >
                          Add Category
                        </button>
                      </div>
                    </div>
                    <div className="mt-8 flow-root mb-10">
                      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  className="w-2/5 py-3.5 pl-4 pr-3 text-left text-sm font-medium text-gray-900 sm:pl-0"
                                >
                                  Category
                                </th>
                                <th
                                  scope="col"
                                  className="w-1/5 px-3 py-3.5 text-left text-sm font-medium text-gray-900"
                                >
                                  Category Level
                                </th>

                                <th
                                  scope="col"
                                  className="w-1/5 px-3 py-3.5 text-left text-sm font-medium text-gray-900"
                                >
                                  Parent Category
                                </th>

                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-medium text-gray-900"
                                ></th>
                              </tr>
                            </thead>
                            {!isLoading ? (
                              customerCategories.customerCategories?.length >
                              0 ? (
                                <tbody className="divide-y divide-gray-200">
                                  {customerCategories.customerCategories?.map(
                                    (item) => (
                                      <tr key={item.categoryID}>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-700 sm:pl-0">
                                          {item.categoryName}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                          {item.level}
                                        </td>

                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                          {item.displayParent}
                                        </td>

                                        <td className="block text-right">
                                          <span className="mt-2 isolate inline-flex rounded-md shadow-sm">
                                            <button
                                              type="button"
                                              onClick={() => {
                                                setCustomerCategoryID(item.categoryID);
                                                setEditOpen(true);
                                              }}
                                              className="relative inline-flex items-center rounded-l-md bg-amber-500 hover:bg-amber-400 focus-visible:outline-amber-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 focus:z-10"
                                            >
                                              Edit
                                            </button>

                                            <button
                                              type="button"
                                              onClick={() => {
                                                if (
                                                  areYouSure(item.categoryID)
                                                ) {
                                                  customerCategoryDelete(
                                                    item.categoryID
                                                  );
                                                }
                                              }}
                                              className="relative -ml-px inline-flex items-center rounded-r-md bg-red-500 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-red-400 focus:z-10"
                                            >
                                              Delete
                                            </button>
                                          </span>
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              ) : (
                                <>
                                  <tbody className="divide-y divide-gray-200">
                                    <tr>
                                      <td
                                        colSpan="4"
                                        className="w-full text-center whitespace-nowrap px-3 py-4 text-sm text-gray-500 col-span-6"
                                      >
                                        -- No categories found --
                                      </td>
                                    </tr>
                                  </tbody>
                                </>
                              )
                            ) : (
                              <>
                                <tbody className="divide-y divide-gray-200">
                                  <tr>
                                    <td
                                      colSpan="5"
                                      className="w-full text-center whitespace-nowrap px-3 py-4 text-sm text-gray-500 col-span-6"
                                    >
                                      <ReactLoading
                                        type={"spin"}
                                        color="#5046e5"
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </>
                            )}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <CustomerCategoryAdd
          open={open}
          setOpen={setOpen}
          addedNotyf={addedNotyf}
          customerID={customerID}
          divisionID={divisionID}
          queryClient={queryClient}
        /> 

        {customerCategoryID && (
          <CustomerCategoryUpdate
            editOpen={editOpen}
            setEditOpen={setEditOpen}
            updatedNotyf={updatedNotyf}
            customerID={customerID}
            queryClient={queryClient}
            customerCategoryID={customerCategoryID}
          />
        )}
      </>
    );
  }
}

export default CustomerCategories;
