import { React, useState, Fragment, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Topbar from "../../Components/Topbar";
import { Dialog, Transition } from "@headlessui/react";
import { HomeIcon } from "@heroicons/react/20/solid";
import { sendGetRequest, useGetRequest, sendPostRequest } from "../../Client";
import UpdateStatus from "./updateStatus";
import UpdateAddress from "./updateAddress";
import AddNote from "./addNote";
import AddItem from "./addItem";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { dateFormatter, domain } from "../../config";
import Ship from "./ship";
import Close from "./close";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
import { imageURL } from "../../config";
import Swal from "sweetalert2";
import { PlusIcon } from "@heroicons/react/20/solid";
import { Link, useNavigate, useParams } from "react-router-dom";

function Order() {
  const [open, setOpen] = useState(false);
  const [shipOpen, setShipOpen] = useState(false);
  const [label, setLabel] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [openNote, setOpenNote] = useState(false);
  const [openItem, setOpenItem] = useState(false);
  const [openAddress, setOpenAddress] = useState(false);
  const [shipData, setShipData] = useState({});
  const [closeData, setCloseData] = useState({});
  const [closeOpen, setCloseOpen] = useState(false);
  const [closeCartons, setCloseCartons] = useState(false);

  const [loading, setLoading] = useState(false);

  const { orderID } = useParams();

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const { data: data, isLoadingOrder } = useQuery({
    queryFn: () => sendGetRequest("orders/" + orderID, {}),
    queryKey: ["order"],
  });

  const { data: dataItems, isLoadingItems } = useQuery({
    queryFn: () => sendGetRequest("orders/items/" + orderID, {}),
    queryKey: ["orders/items/"],
  });

  const { data: dataStatuses, isLoading } = useQuery({
    queryFn: () => sendGetRequest("orders/statuses/" + orderID, {}),
    queryKey: ["orders/statuses/"],
  });

  const { data: dataNotes, isLoadingNotes } = useQuery({
    queryFn: () => sendGetRequest("orders/notes/" + orderID, {}),
    queryKey: ["orders/notes/"],
  });

  const { status: statusDropdownStatuses, data: dataDropdownStatuses } =
    useGetRequest("statuses/list", {});

  // confirmation notifications
  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
    duration: 4000,
  });

  const updatedNotyf = () => {
    notyf.success("Address updated");
  };

  const getLabels = async () => {
    const res = await sendGetRequest("orders/" + orderID + "/label", {});

    const linkSource = `data:application/pdf;base64,${res.label}`;
    const downloadLink = document.createElement("a");
    const fileName = `Label_${orderID}.pdf`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const closeOrder = async (data, closeCartons) => {
    const res = await sendPostRequest("orders/" + orderID + "/ship", {
      close: 1,
      data: JSON.stringify(data),
      closeCartons: closeCartons,
    });

    //todo
    setTimeout(() => {
      window.location.reload(false);
    }, 500);
  };

  const shipOrder = async (data, shipCartons, shippingCost, labels) => {

    const res = await sendPostRequest("orders/" + orderID + "/ship", {
      data: JSON.stringify(data),
      shipCartons: shipCartons,
      labels: labels,
      shippingCost: Number(shippingCost),
    });

    if (res.status == "unsuccessful") {
      setLoading(false);
      notyf.success({
        message: `Error - ${res.reason}`,
        background: "#FF0000",
      });
    }

    if (res.status == "success") {
      notyf.success("Label generated");

      setTimeout(() => {
        window.location.reload(false);
      }, 500);
    }

    // trigger POD charge. /ship doesn't take in order number (which we need), and this charge shouldn't be triggered when closing so cleaner to keep it seperate
    const resPod = await sendPostRequest("orders/" + orderID + "/pod", {});

    console.log('resPod', resPod);
  };

  const packingSlip = async () => {
    const res = await sendPostRequest("order/packing/" + orderID, {});
    window.open(domain + "/order/packing/" + orderID);
  };

  // confirm delete
  const areYouSure = (id) => {
    Swal.fire({
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#de3333",
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("delete", id);
        itemDelete(id);
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  const itemDelete = async (id) => {
    const res = await sendPostRequest("order/item/delete", {
      orderItemID: id,
    });

    setTimeout(() => {
      window.location.reload(false);
    }, 500);
  };

  // confirm delete
  const areYouSureOrder = (id) => {
    Swal.fire({
      title: "Are you sure? This will delete the entire order.",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#de3333",
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("delete", id);
        orderDelete(id);
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  const orderDelete = async (id) => {
    const res = await sendPostRequest("order/delete", {
      orderID: id,
    });

    navigate(`/dashboard`, {
      replace: true,
      state: { added: 1 },
    });
  };

  if (
    // status == "fetched" &&
    // statusItems == "fetched" &&
    statusDropdownStatuses == "fetched"
  ) {
    return (
      <>
        <div className="flex flex-row w-full">
          <Navbar />

          <div className="flex flex-col w-full bg-gray-100 ml-60">
            <Topbar />

            <nav className="flex ml-10 mt-5" aria-label="Breadcrumb">
              <ol
                role="list"
                className="flex space-x-4 rounded-md bg-white px-6 shadow"
              >
                <li className="flex">
                  <div className="flex items-center">
                    <a
                      href="/Dashboard"
                      className="text-gray-400 hover:text-gray-500"
                    >
                      <HomeIcon
                        className="h-5 w-5 flex-shrink-0"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Home</span>
                    </a>
                  </div>
                </li>

                <li key={orderID} className="flex">
                  <div className="flex items-center">
                    <svg
                      className="h-full w-6 flex-shrink-0 text-gray-200"
                      viewBox="0 0 24 44"
                      preserveAspectRatio="none"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                    </svg>
                    <a
                      href={""}
                      className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                      aria-current="page"
                    >
                      #{orderID}
                    </a>
                  </div>
                </li>
              </ol>
            </nav>

            {/* content */}
            <div className="bg-white ml-10 mr-10 mt-10 mb-10 border">
              <div className="pt-2 sm:px-6 sm:py-6 lg:px-8">
                <div className="space-y-2 px-4 sm:flex sm:items-baseline sm:justify-between sm:space-y-0 sm:px-0">
                  <div className="flex flex-row items-center">
                    <div>
                      <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                        Order #{orderID}
                      </h1>
                    </div>
                  </div>

                  <div className="flex flex-row items-center">
                    <div>
                      <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                        {data?.order[0]?.customerName}
                      </h1>
                    </div>
                  </div>

                  <div className="flex flex-row">
                    {data?.order[0]?.tracking == "" ||
                    data?.order[0]?.tracking == "Closed" ? null : (
                      <div className="ml-5">
                        <button
                          type="button"
                          onClick={() => getLabels()}
                          className="mt-5 block rounded-md bg-slate-600 px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm "
                        >
                          Labels
                        </button>
                      </div>
                    )}

                    <div className="ml-5">
                      <button
                        type="button"
                        onClick={() => {
                          packingSlip();
                        }}
                        className="mt-5 block rounded-md bg-gray-600 px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm "
                      >
                        Packing Slip
                      </button>
                    </div>

                    {data?.order[0]?.tracking == "" ? (
                      <>
                        <div className="ml-5">
                          <button
                            type="button"
                            // onClick={() => closeOrder()}
                            onClick={() => setCloseOpen(true)}
                            className="mt-5 block rounded-md bg-sky-600 px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm "
                          >
                            Close
                          </button>
                        </div>
                        <div className="ml-5">
                          <button
                            type="button"
                            onClick={() => setShipOpen(true)}
                            className="mt-5 block rounded-md bg-green-600 px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm "
                          >
                            Ship Order
                          </button>
                        </div>
                      </>
                    ) : null}

                    <div className="ml-5">
                      <button
                        type="button"
                        onClick={() => {
                          setOpenStatus(true);
                        }}
                        className="mt-5 block rounded-md bg-amber-600 px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm "
                      >
                        Update Status
                      </button>
                    </div>

                    <div className="ml-5">
                      <button
                        type="button"
                        onClick={() => {
                          setOpenNote(true);
                        }}
                        className="mt-5 block rounded-md bg-teal-600 px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm "
                      >
                        Add Note
                      </button>
                    </div>
                  </div>
                  <a
                    href="#"
                    className="text-sm font-medium text-indigo-600 hover:text-indigo-500 sm:hidden"
                  >
                    View invoice
                    <span aria-hidden="true"> &rarr;</span>
                  </a>
                </div>

                <div className="grid grid-cols-2 gap-4 mt-10">
                  <div className="border flex flex-row justify-between rounded-t-md">
                    {" "}
                    <div className="w-1/2">
                      <div className="flex flex-row justify-between bg-gray-100 border-b border-gray-200 px-2 py-3 sm:px-6 rounded-t-md">
                        <h3 className="text-sm font-semibold leading-6 text-gray-900">
                          Shipping To
                        </h3>
                        <div
                          onClick={() => setOpenAddress(true)}
                          className="text-xs font-semibold leading-6 text-gray-500 hover:cursor-pointer"
                        >
                          Edit
                        </div>
                      </div>
                      <dd className="mt-3 text-gray-500 text-sm ml-6 mb-4">
                        <span className="block mt-1">
                          {data?.order[0]?.orderName}
                        </span>
                        <span className="block">
                          {data?.order[0]?.orderCompany}
                        </span>
                        <span className="block mt-1">
                          {data?.order[0]?.orderAddress1}
                        </span>
                        <span className="block mt-1">
                          {data?.order[0]?.orderCity},{" "}
                          {data?.order[0]?.orderState}{" "}
                          {data?.order[0]?.orderZip}
                        </span>
                      </dd>
                    </div>
                    <div className="w-1/2">
                      <div className="bg-gray-100 border-b border-gray-200 px-2 py-3 sm:px-6 rounded-t-md">
                        <h3 className="text-sm font-semibold leading-6 text-gray-900">
                          Tracking Number(s)
                        </h3>
                      </div>
                      <dd className="ml-6 mt-3 text-gray-500 text-sm">
                        <div className="flex flex-col">
                          <span className="block">
                            {data?.order[0]?.carrierName}{" "}
                            {data?.order[0]?.serviceName}{" "}
                          </span>

                          <span className="block">
                            {data?.order[0]?.tracking}
                          </span>
                        </div>
                      </dd>
                    </div>
                  </div>

                  <div className="border rounded-t-md">
                    {" "}
                    <div className="">
                      <div className="flex flex-row justify-between bg-gray-100 border-b border-gray-200 px-2 py-3 sm:px-6 rounded-t-md">
                        <h3 className="text-sm font-semibold leading-6 text-gray-900">
                          Status History
                        </h3>
                      </div>
                      <table className="min-w-full divide-y divide-gray-300 ml-3">
                        <tbody className="divide-y divide-gray-0">
                          {dataStatuses?.statuses?.map((status) => (
                            <tr
                              key={status.statusName + status.datetime}
                              className="justify-between"
                            >
                              <td className="w-[40%] whitespace-nowrap px-3 py-4 text-xs text-gray-500 ml-8">
                                {dateFormatter(status.datetime)}
                              </td>
                              <td
                                className={`w-[30%] whitespace-nowrap py-1 pl-24 pr-3 text-xs font-medium text-${status.colour}-900 sm:pl-0`}
                              >
                                <span
                                  style={{
                                    backgroundColor: status.colour,
                                    padding: 5,
                                    color: status.textColour,
                                    borderRadius: 5,
                                  }}
                                >
                                  {status.statusName}
                                </span>
                              </td>
                              <td className="text-xs text-gray-500">
                                {status.firstName} {status.lastName}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="mt-8 flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              ></th>

                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                Code
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Description
                              </th>

                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Ordered
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Shipped
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Price
                              </th>
                              <th>
                                {""}
                                <PlusIcon
                                  onClick={() => {
                                    setOpenItem(true);
                                  }}
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {dataItems?.items?.map((item) => (
                              <tr key={item.orderItemID}>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  <img
                                    className="h-11 w-11 rounded-full"
                                    src={imageURL + item.image}
                                    alt=""
                                  />
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  {item.productCode}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {item.description}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {item.quantityOrdered}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  0
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                ${parseFloat(item.unitPrice * item.quantityOrdered).toFixed(2)}
                                  
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    onClick={() => {
                                      areYouSure(item.orderItemID);
                                    }}
                                    className="relative -ml-px inline-flex items-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-red-400 focus:z-10"
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-8 flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                Notes
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            <div className="mt-6">
                              <div>
                                {dataNotes?.notes.length === 0 ? (
                                  <div className="rounded-md mr-40 border border-gray-200 bg-gray-50 w-[100%] flex justify-center text-sm font-medium text-gray-500 py-2 mb-4">
                                    No Notes
                                  </div>
                                ) : (
                                  dataNotes?.notes.map((note) => (
                                    <div
                                      key={note.noteID}
                                      className="flex flex-row items-start"
                                    >
                                      <div className="flex flex-col items-start w-[30%]">
                                        <div className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900 ml-10">
                                          {note.firstName} {note.lastName}
                                        </div>
                                        <div className="whitespace-nowrap px-2 text-xs text-gray-500 ml-10 mb-10">
                                          {dateFormatter(note.added)}
                                        </div>
                                      </div>
                                      <div className="w-[50%] text-sm font-medium text-gray-500 justify-end mt-2">
                                        {note.note}
                                      </div>
                                    </div>
                                  ))
                                )}
                              </div>
                            </div>
                          </tbody>
                        </table>
                      </div>
                      <div
                        onClick={() => areYouSureOrder(orderID)}
                        className="text-red-500 text-sm text-end p-4"
                      >
                        Delete Order
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* content */}
          </div>
        </div>

        <Close
          closeOpen={closeOpen}
          setCloseOpen={setCloseOpen}
          closeData={closeData}
          setCloseData={setCloseData}
          closeCartons={closeCartons}
          closeOrder={closeOrder}
        />

        <Ship
          shipOpen={shipOpen}
          setShipOpen={setShipOpen}
          shipData={shipData}
          setShipData={setShipData}
          shipOrder={shipOrder}
          loading={loading}
          setLoading={setLoading}
        />

        <Transition.Root show={label} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setLabel}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="mt-10 fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                    <div>
                      {/* <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                      <CheckIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div> */}
                      <div className="mt-2 text-center sm:mt-5">
                        <div className="mt-1">
                          <img
                            className="h-100"
                            src="https://www.joff.me.uk/label.png"
                            alt="new"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-6">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={() => setLabel(false)}
                      >
                        Back to order
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <UpdateStatus
          setOpenStatus={setOpenStatus}
          openStatus={openStatus}
          dataDropdownStatuses={dataDropdownStatuses}
          orderID={orderID}
          queryClient={queryClient}
          status={data?.order[0]?.statusName}
          statusID={data?.order[0]?.orderStatusID}
        />

        <AddNote
          setOpenNote={setOpenNote}
          openNote={openNote}
          orderID={orderID}
          queryClient={queryClient}
        />

        <AddItem
          setOpenItem={setOpenItem}
          openItem={openItem}
          orderID={orderID}
          queryClient={queryClient}
          customerID={data?.order[0]?.customerID}
        />

        {data ? (
          <UpdateAddress
            setOpenAddress={setOpenAddress}
            openAddress={openAddress}
            orderID={orderID}
            updatedNotyf={updatedNotyf}
            queryClient={queryClient}
            customerID={data?.order[0]?.customerID}
            service={data?.order[0]?.serviceName}
            carrierName={data?.order[0]?.carrierName}
            serviceID={data?.order[0]?.serviceID}
          />
        ) : null}
      </>
    );
  }
}

export default Order;
